import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./Footer.scss";

import logoSvg from "./../App/logo.svg";

export default class Footer extends Component {
  componentDidMount() {
    this.updateFooterSpacer();
    // this.props.setFooterHeight(document.getElementById("footer").clientHeight);
    window.addEventListener("resize", this.updateFooterSpacer.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateFooterSpacer.bind(this));
  }

  updateFooterSpacer() {
    this.props.setFooterHeight(document.getElementById("footer").clientHeight);
  }

  render() {
    return (
      <footer id="footer">
        <div className="container-fluid">
          <div className="d-flex flex-row flex-wrap">
            <div className="col-lg-2 col-12 order-1">
              <img src={logoSvg} className="logo" rel="" alt="" />
            </div>

            <div className="col-lg-2 col-md-6 col-12 order-lg-2 order-5">
              <ul className="footer-list legal">
                <li>&copy; Zoon Media {new Date().getFullYear()}</li>
                <li>All Rights Reserved</li>
                <li style={{paddingTop: "10px"}}>Developed by <a href="https://agilecollab.ca" target="_blank" rel='noreferrer noopener' style={{color: "#f7931e"}}>agileCollab</a></li>
              </ul>
            </div>
            <div className="col-lg-2 col-sm-4 col-12 order-lg-2 order-md-4 order-4">
              <ul className="footer-list">
                <li>
                  <NavLink to="/site-map" className="footer-link">
                    Site Map
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/terms-conditions" className="footer-link">
                    Terms &amp; Conditions
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/privacy-policy" className="footer-link">
                    Privacy Policy
                  </NavLink>
                </li>
                <li>
                  <a
                    href="https://app.zoon.ca"
                    className="footer-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Client Portal
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-sm-4 col-12 order-md-2 order-2">
              <ul className="footer-list">
                <li>
                  <NavLink to="/" className="footer-link">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about-zoon-media" className="footer-link">
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/portfolio" className="footer-link">
                    Portfolio
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/blog" className="footer-link">
                    Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact-zoon-media" className="footer-link">
                    Contact
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/book-now" className="footer-link">
                    Book Now
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-sm-4 col-12 order-md-3 order-3">
              <ul className="footer-list">
                <li>
                  <NavLink
                    to="/real-estate-photography-experts"
                    className="footer-link"
                  >
                    Photography
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/real-estate-video-production"
                    className="footer-link"
                  >
                    Video
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/virtual-staging-real-estate-photography"
                    className="footer-link"
                  >
                    Virtual Staging
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/interactive-real-estate-virtual-tours"
                    className="footer-link"
                  >
                    3D Virtual Tours
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/property-measurements-floor-plan-experts"
                    className="footer-link"
                  >
                    Floorplans &amp; Measurements
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
