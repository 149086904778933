import React, { Component } from "react";

import "./Sidebar.scss";

import iconInstagram from "./icons/instagram.svg";
import iconFacebook from "./icons/facebook.svg";
import iconHouzz from "./icons/houzz.svg";
import iconVimeo from "./icons/vimeo.svg";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { socialName: "" };
  }

  render() {
    let { socialName } = this.state;
    let { socialHeight } = this.props;

    return (
      <div className="sidebar">
        <div className="bar-wrap title">
          <span className="side-title">Zoon Media</span>
        </div>

        <div className="bar-wrap social" style={{ height: socialHeight }}>
          <div className="side-social">
            <span className="social-name">{socialName}</span>
            <ul className="social-list">
              <li>
                <a
                  className="instagram"
                  href="https://www.instagram.com/zoonmedia/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseOver={() => this.setState({ socialName: "Instagram" })}
                  onMouseLeave={() => this.setState({ socialName: "" })}
                >
                  <span>
                    <object type="image/svg+xml" data={iconInstagram}>
                      <img src={iconInstagram} alt="" />
                    </object>
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="facebook"
                  href="https://www.facebook.com/zoonphoto/?ref=br_rs"
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseOver={() => this.setState({ socialName: "Facebook" })}
                  onMouseLeave={() => this.setState({ socialName: "" })}
                >
                  <span>
                    <object type="image/svg+xml" data={iconFacebook}>
                      <img src={iconFacebook} alt="" />
                    </object>
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="houzz"
                  href="https://www.houzz.com/pro/zoonmedia/zoon-real-estate-media"
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseOver={() => this.setState({ socialName: "Houzz" })}
                  onMouseLeave={() => this.setState({ socialName: "" })}
                >
                  <span>
                    <object type="image/svg+xml" data={iconHouzz}>
                      <img src={iconHouzz} alt="" />
                    </object>
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="vimeo"
                  href="https://vimeo.com/zoonmedia"
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseOver={() => this.setState({ socialName: "Vimeo" })}
                  onMouseLeave={() => this.setState({ socialName: "" })}
                >
                  <span>
                    <object type="image/svg+xml" data={iconVimeo}>
                      <img src={iconVimeo} alt="" />
                    </object>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
