import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";

import "./MainNav.scss";
import zoonLogoSVG from "./logo.svg";
import zoonEmblemSVG from "./emblem.svg";

export default class MainNav extends Component {
  constructor(props) {
    super(props);
    this.state = { toggleMenu: false, toggleDD: false };
    this.ddService = React.createRef();
  }

  componentDidMount() {
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize());
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ toggleDD: nextProps.collapsed || false });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenSize());
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.menu.contains(e.target)) return;

    this.setState({ toggleDD: false });
  };

  updateScreenSize = () => {
    if (window.innerWidth !== 0)
      this.setState({
        screenWidth: window.innerWidth,
        toggleMenu: false,
        toggleDD: false
      });
  };

  render() {
    let { toggleDD, toggleMenu } = this.state;

    return (
      <nav
        className="navbar navbar-toggleable-md navbar-light fixed-top"
        ref={ref => (this.menu = ref)}
      >
        <NavLink to="/" className="navbar-brand">
          <img
            src={zoonLogoSVG}
            height="30"
            alt="ZOON Real Estate Media Professionals"
          />
        </NavLink>
        <button
          className="navbar-toggler navbar-toggler-right hidden-lg-up"
          type="button"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => this.setState({ toggleMenu: !toggleMenu })}
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div
          className={`collapse navbar-collapse ${
            toggleMenu ? "show visible" : ""
          } `}
        >
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink
                to="/"
                exact
                className="nav-link"
                activeClassName="active"
                onClick={() => this.setState({ toggleMenu: false })}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/about-zoon-media"
                className="nav-link"
                activeClassName="active"
                onClick={() => this.setState({ toggleMenu: false })}
              >
                About
              </NavLink>
            </li>
            <li className={`nav-item top-drop ${toggleDD ? "show" : null}`}>
              <button
                type="button"
                onClick={() => {
                  this.setState({ toggleDD: !toggleDD });
                }}
                className="nav-link drop"
                ref={this.ddService}
              >
                Services <FontAwesomeIcon icon={faAngleDown} />
                <ul className="dropdown">
                  <li>
                    <NavLink
                      to="/real-estate-photography-experts"
                      className="nav-link"
                      activeClassName="active"
                      onClick={() => this.setState({ toggleMenu: false })}
                    >
                      Photography
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/real-estate-video-production"
                      className="nav-link"
                      activeClassName="active"
                      onClick={() => this.setState({ toggleMenu: false })}
                    >
                      Video
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/virtual-staging-real-estate-photography"
                      className="nav-link"
                      activeClassName="active"
                      onClick={() => this.setState({ toggleMenu: false })}
                    >
                      Virtual Staging
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/interactive-real-estate-virtual-tours"
                      className="nav-link"
                      activeClassName="active"
                      onClick={() => this.setState({ toggleMenu: false })}
                    >
                      3D Virtual Tours
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/property-measurements-floor-plan-experts"
                      className="nav-link"
                      activeClassName="active"
                      onClick={() => this.setState({ toggleMenu: false })}
                    >
                      Floorplans &amp; Measurements
                    </NavLink>
                  </li>
                </ul>
              </button>
            </li>
            <li className="nav-item">
              <NavLink
                to="/portfolio"
                className="nav-link"
                activeClassName="active"
                onClick={() => this.setState({ toggleMenu: false })}
              >
                Portfolio
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                to="/blog"
                className="nav-link"
                activeClassName="active"
                onClick={() => this.setState({ toggleMenu: false })}
              >
                Blog
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                to="/client-portal"
                className="nav-link"
                activeClassName="active"
                onClick={() => this.setState({ toggleMenu: false })}
              >
                Portal
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/contact-zoon-media"
                className="nav-link"
                activeClassName="active"
                onClick={() => this.setState({ toggleMenu: false })}
              >
                Contact
              </NavLink>
            </li>

            <li className="nav-image">
              <img src={zoonEmblemSVG} alt="ZOON" />
            </li>

            <li className="nav-item">
              <NavLink
                to="/book-now"
                className="nav-link"
                activeClassName="active"
                onClick={() => this.setState({ toggleMenu: false })}
              >
                Book Now
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
