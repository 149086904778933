import alt from "../alt";

class LocationActions {
  initialize() {
    return dispatch => {
      dispatch();
      console.log('init geo')
      if(window.geoip2){
        console.log('geo ip is loaded...');
        window.geoip2.city( this.handleResponse, this.handleError );
      } else {
        this.handleError("GeoIp not loaded. Default to Calgary.");
      }
      
    };
  }

  handleResponse(geoipResponse) {
    console.log('got geo', geoipResponse)
    return geoipResponse;
  }

  handleError(error) {
    console.log('geo error', error)
    return {city: "Calgary", region: "Alberta"};
  }

  updateLocation(postalCode) {
    return dispatch => {
      const geocoder = new window.google.maps.Geocoder();
      const address = postalCode;
      geocoder.geocode({address}, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const city = results[0].address_components[2].long_name;

          if (["Calgary", "Edmonton"].includes(city))
            dispatch({city, region: "Alberta"});
          else alert("Postal Code Outside Service Area.");
        } else {
          alert("Request failed.");
        }
      });
    };
  }

  updateClientType(clientType) {
    return clientType;
  }
}

export default alt.createActions(LocationActions);
