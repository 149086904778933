import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

import WebFont from "webfontloader";

import App from "./client/App/App";
import * as serviceWorker from "./client/registerServiceWorker";

WebFont.load({
  google: {
    families: ["Open+Sans:300,400,700", "Oswald:300,400,700"]
  }
  // loading: () => {
  //   console.log("LOADING");
  // },
  // fontloading: (fontFamily, fontDescription) => {
  //   console.log("FONT LOADING", fontFamily, fontDescription);
  // },
  // active: () => {
  //   sessionStorage.fonts = true;
  // }
});

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
