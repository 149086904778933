import React, {Component} from "react";

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import {TransitionGroup, CSSTransition} from "react-transition-group";

import "./App.scss";
import ScrollToTop from "./ScrollToTop";
import {detect} from "detect-browser";

import LocationActions from "../../actions/locationActions";
//import LocationStore from "../../stores/locationStore";

import asyncComponent from "./AsyncComponent";

import MainNav from "../MainNav/MainNav";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";

const AsyncHome = asyncComponent(() => import("../Home/Home"));
const AsyncAbout = asyncComponent(() => import("../About/About"));
const AsyncServicePhotography = asyncComponent(() =>
  import("../Services/Photography/Photography")
);
const AsyncServiceVideo = asyncComponent(() =>
  import("../Services/Video/Video")
);
const AsyncServiceVirtualStaging = asyncComponent(() =>
  import("../Services/VirtualStaging/VirtualStaging")
);
const AsyncServiceVirtualTours = asyncComponent(() =>
  import("../Services/VirtualTours/VirtualTours")
);
const AsyncServiceFloorplansMeasurements = asyncComponent(() =>
  import("../Services/FloorplansMeasurements/FloorplansMeasurements")
);
const AsyncPortfolio = asyncComponent(() => import("../Portfolio/Portfolio"));
const AsyncPortal = asyncComponent(() => import("../Portal/Portal"));
const AsyncContact = asyncComponent(() => import("../Contact/Contact"));
const AsyncBookNow = asyncComponent(() => import("../BookNow/BookNow"));
const AsyncNotFound = asyncComponent(() => import("../NotFound/NotFound"));

const AsyncSitemap = asyncComponent(() =>
  import("../Resources/Sitemap/Sitemap")
);
const AsyncTermsConditions = asyncComponent(() =>
  import("../Resources/TermsConditions/TermsConditions")
);
const AsyncPrivacy = asyncComponent(() =>
  import("../Resources/Privacy/Privacy")
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      footerHeight: 0,
      collapsed: false
    };
  }

  componentDidMount() {
    LocationActions.initialize();

    const browser = detect();

    document.body.classList.add(browser.name);
  }

  render() {
    let {footerHeight, collapsed} = this.state;

    return (
      <Router>
        <Route
          render={({location}) => (
            <React.Fragment>
              <ScrollToTop>
                <MainNav
                  toggleCollapsed={() => this.setState({collapsed: !collapsed})}
                />
                {/* style={{ marginBottom: `${footerHeight}px` }} */}
                <div className="wrapper">
                  <div className="content-wrapper">
                    <React.Fragment>
                      <TransitionGroup className="trangroup-wrap">
                        <CSSTransition
                          key={location.key}
                          classNames="fade"
                          timeout={{appear: 1000, enter: 2000, exit: 1000}}
                          mountOnEnter={true}
                          unmountOnExit={true}
                        >
                          <Switch location={location}>
                            <Route exact path="/" component={AsyncHome} />
                            <Route
                              path="/about-zoon-media"
                              component={AsyncAbout}
                            />

                            <Route
                              exact
                              path="/real-estate-photography-experts"
                              component={AsyncServicePhotography}
                            />
                            <Route
                              path="/real-estate-video-production"
                              component={AsyncServiceVideo}
                            />
                            <Route
                              path="/virtual-staging-real-estate-photography"
                              component={AsyncServiceVirtualStaging}
                            />
                            <Route
                              path="/interactive-real-estate-virtual-tours"
                              component={AsyncServiceVirtualTours}
                            />
                            <Route
                              path="/property-measurements-floor-plan-experts"
                              component={AsyncServiceFloorplansMeasurements}
                            />
                            <Route
                              path="/portfolio"
                              component={AsyncPortfolio}
                            />
                            <Route
                              path="/client-portal"
                              component={AsyncPortal}
                            />
                            <Route
                              path="/contact-zoon-media"
                              component={AsyncContact}
                            />
                            <Route path="/book-now" component={AsyncBookNow} />
                            <Route path="/site-map" component={AsyncSitemap} />
                            <Route
                              path="/terms-conditions"
                              component={AsyncTermsConditions}
                            />
                            <Route
                              path="/privacy-policy"
                              component={AsyncPrivacy}
                            />
                            <Route component={AsyncNotFound} />
                          </Switch>
                        </CSSTransition>
                        <Footer
                          setFooterHeight={h => {
                            this.setState({footerHeight: h});
                          }}
                        />
                      </TransitionGroup>
                      {/* <div
                        className="footer-spacer"
                        style={{ height: `${footerHeight}px` }}
                      /> */}
                    </React.Fragment>
                  </div>
                </div>
                {/* <Footer
                  setFooterHeight={h => {
                    this.setState({ footerHeight: h });
                  }}
                /> */}
                <Sidebar socialHeight={footerHeight} />
              </ScrollToTop>
            </React.Fragment>
          )}
        />
      </Router>
    );
  }
}

export default App;
